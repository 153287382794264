import { FishConfig } from '~/types/game';

export const fishConfigs: FishConfig[] = [
  { image: '1.png' },
  { image: '2.png' },
  { image: '3.png' },
  { image: '4.png' },
  { image: '5.png' },
  { image: '6.png' },
  { image: '7.png', fixedY: 160 },
  { image: '8.png' },
  { image: '9.png' },
  { image: '10.png' },
  { image: '11.png' },
  { image: '12.png' },
  { image: '13.png' },
  { image: '14.png' },
  { image: '15.png', fixedY: 60 },
  { image: '16.png' },
  { image: '17.png' },
  { image: '18.png', fixedX: 240 },
  { image: '19.png', fixedX: 280 },
  { image: '20.png', fixedY: 180 },
  { image: '21.png' },
  { image: '22.png' },
  { image: '23.png' },
  { image: '24.png', fixedY: 100 },
  { image: '25.png' },
  { image: '26.png' },
  { image: '27.png', fixedX: 200 },
  { image: '28.png', fixedY: 160 },
  { image: '29.png' },
  { image: '30.png' },
  { image: '31.png' },
  { image: '32.png' },
  { image: '33.png', fixedY: 160 },
  { image: '34.png' },
  { image: '35.png', fixedX: 200 },
  { image: '36.png' },
  { image: '37.png' },
  { image: '38.png', fixedY: 160 },
  { image: '39.png' },
  { image: '40.png' },
  { image: '41.png', fixedY: 160 },
  { image: '42.png' },
  { image: '43.png', fixedY: 180 },
  { image: '44.png' },
  { image: '45.png' },
  { image: '46.png', fixedY: 80 },
  { image: '47.png' },
  { image: '48.png' },
  { image: '49.png' },
  { image: '50.png' },
  { image: '51.png' },
];
